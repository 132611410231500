import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { colors, borderRadiuses } from '@entur/tokens';
import { TokenTable } from '~/components/TokensTable/TokenTable';
import PageHeader from '~/components/PageHeader';
import { Table, TableHead, TableRow, TableBody, DataCell, HeaderCell } from '@entur/table';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Vårt spacing system baserer seg på standardariserte klesstørrelser fra XS til XXXL.
Spacing M er default. Størrelsesskalaen baserer seg på 4-punktsystem hvor verdien må kunne deles på 4 som gjør det enkelt å standardisere størrelsesforhold i designet.`}</p>
    <p>{`Spacing brukes for eksempel innenfor konteksten til en komponent (for eksempel avstand mellom en label og et inputfelt).
Det kan også brukes til å lage avstand mellom komponenter i en layout (for eksempel avstand mellom et inputfelt og en nedtrekksmeny). Denne avstandsskalaen kontrollerer mengden av whitespace mellom komponenter og layout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/tokens/dist/styles.scss';

.card {
  padding: $space-large;
}
`}</code></pre>
    <TokenTable tokenKey="space" example={({
      value
    }) => <div style={{
      background: colors.brand.lavender,
      borderRadius: borderRadiuses.default,
      width: value,
      height: value
    }} />} mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      